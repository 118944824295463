import React from 'react';
import { UitkCard, UitkCardContentSection } from 'uitk-react-cards';
import TripInfoOverview from './TripInfoOverview';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import TripInfoDetail from './TripInfoDetail';
import { ViewMedium, ViewSmall, Viewport } from '@shared-ui/viewport-context';
import { TripInfoDetailFields } from 'common/__generated__/api/types';
import TripInfos = TripInfoDetailFields.TripInfos;
import TripDetails = TripInfoDetailFields.AllTrips;
interface Props {
  data?: TripDetails;
  setTripInfoData?: (value) => void;
  tripInfoData: TripInfos;
}
const TripsTabContent = (props: Props) => {
  const { tripInfoData, data, setTripInfoData } = props;
  return (
    <UitkCard>
      <Viewport>
        <ViewSmall>
          <UitkCardContentSection padded={false}>
            {!tripInfoData ? (
              <div className="trip-info-container-view-small">
                <TripInfoOverview data={data} setTripInfoData={setTripInfoData} />
              </div>
            ) : (
              <div className={'trip-bookings-container-view-small'}>
                <TripInfoDetail setTripInfoData={setTripInfoData} tripInfoData={tripInfoData} />
              </div>
            )}
          </UitkCardContentSection>
        </ViewSmall>
        <ViewMedium>
          <UitkLayoutFlex space={'two'} dataTestId="largeContent">
            <UitkLayoutFlexItem grow={1}>
              <div>
                <div className="trip-info-container-view-medium">
                  <TripInfoOverview data={data} setTripInfoData={setTripInfoData} />
                </div>
              </div>
            </UitkLayoutFlexItem>
            <UitkLayoutFlexItem grow={1} style={{ maxWidth: '50%', minWidth: '50%' }}>
              <div className={'trip-bookings-container-view-medium'}>
                <TripInfoDetail tripInfoData={tripInfoData ? tripInfoData : data.tripInfos[0]} />
              </div>
            </UitkLayoutFlexItem>
          </UitkLayoutFlex>
        </ViewMedium>
      </Viewport>
    </UitkCard>
  );
};

export default TripsTabContent;
