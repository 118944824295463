import * as React from 'react';
import { inject } from 'mobx-react';
import { Footer, Header } from '@gc/global-components';
import { CustomerNotifications } from 'components/CustomerNotification';
import { CustomerNotificationLocation } from '@shared-ui/customer-notifications';
import { VaContainer } from 'components/VirtualAgentControl';
import { HelpArticlesWrapper as HelpArticles } from 'components/Articles';
import { Experiment, ExperimentControl, ExperimentVariant, useExperiment } from '@shared-ui/experiment-context';
import { EVENTS } from 'utils/logger-events';
import { ErrorBoundary } from 'bernie-view';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { egClickStreamConstants } from 'common/constant';
import { VirtualAgentControlStore } from 'stores/virtual-agent-control-store';
import { prepareClickStreamPayload } from 'common/helper';
import { gql, useQuery } from '@apollo/client';
import { useBexApi } from '@shared-ui/bex-api-context';
import { GetHelpCenterConfigQuery } from 'common/__generated__/api/types';
import { WelcomeBanner } from 'components/WelcomeBanner';
import { IntentButton } from 'components/IntentButtons';
import { ClientLogger } from 'bernie-client';
import { TaapAcademy } from 'components/TaapAcademy';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading } from 'uitk-react-text';
import { processFeatureGateOverride } from 'utils/featureGatesOverride';
import { Trips } from 'components/TripInfo';
import { HelpCenterSearch } from '@shared-ui/retail-help-center-search';
import { SignInCta } from 'components/SignInCta/sign-in-cta';
import { AppDownloadBanner } from 'components/AppDownloadBanner';
import { Qualtrics } from '@shared-ui/customer-qualtrics';
import { VirtualAgentContextProvider } from '@shared-ui/retail-help-center-util';

const logger = ClientLogger.getLoggerWithIdentifier('helpcenter');
export interface HelpCenterProps {
  virtualAgentControlStore?: VirtualAgentControlStore;
}
const QUERY = gql`
  query getHelpCenterConfig($context: ContextInput!) {
    helpCenterPersonalization(context: $context) {
      helpCenterPersonalizedView {
        cspToHelpCenterArticleMap {
          cspArticleId
          hcArticleId
        }
        vaLayoutVariant
        banner {
          ...BannerFields
        }
        ...IntentConfigFields
        isVacEnabled
        taapAcademyComponent {
          ...TaapAcademyComponentFields
        }
        travelerTripDetailedOverview {
          ...TripInfoDetailFields
        }
        helpArticleTitle
      }
      helpCenterSignInCTAView {
        ...SignInCTAFields
      }
    }
  }
  ${WelcomeBanner.fragment}
  ${IntentButton.fragment}
  ${SignInCta.fragment}
  ${TaapAcademy.fragment}
  ${Trips.fragment}
`;

const HelpCenter: React.FC<HelpCenterProps> = inject('virtualAgentControlStore')(
  ({ virtualAgentControlStore }: HelpCenterProps) => {
    const trackClick = useEgClickstream();
    const { context } = useBexApi();
    const { exposure, logExperiment } = useExperiment('Help_Center_Intelligent_Search_w_Follow_Up_Actions_AB_Test');
    const { data } = useQuery<GetHelpCenterConfigQuery>(QUERY, { variables: { context } });
    React.useEffect(() => {
      logger.logEvent(EVENTS.UI_MESSAGE, {
        message: `Variant HELPCENTER_COMPONENT_WITH_CONFIG loaded for ${context.siteId}`,
      });
    }, [logger]);

    React.useEffect(() => {
      if (data?.helpCenterPersonalization) {
        logExperiment(exposure);
        processFeatureGateOverride();
        const {
          egClickStreamEventNames,
          egClickStreamEventTypes,
          egClickStreamEventCategories,
          egClickStreamEventWorkFlowItems,
        } = egClickStreamConstants;
        const clickStreamEvent = prepareClickStreamPayload(
          virtualAgentControlStore,
          egClickStreamEventNames.helpCenterPageView,
          egClickStreamEventTypes.pageView,
          egClickStreamEventCategories.helpCenter,
          undefined,
          egClickStreamEventWorkFlowItems
        );
        trackClick(clickStreamEvent);
      }
    }, [data]);

    const renderIntelligentSearch = context.locale === 'en_US' && exposure.bucket === 1;

    return (
      <ErrorBoundary label="Help center Error Boundary" fallback={<div />}>
        <VirtualAgentContextProvider>
            <div className={'middle-bg'}>
              <Header />
              <Experiment name="HELP_CENTER_PWA_QUALTRICS">
                <ExperimentControl />
                <ExperimentVariant bucket={1}>
                  <Qualtrics skipSsr />
                </ExperimentVariant>
              </Experiment>
              <div role="main">
                <div>
                  <div className={'middle-con global_navigation__header__default__max_width'}>
                    <Experiment name="HELP_CENTER_DISPLAY_TRAVEL_ADVISORY">
                      <ExperimentControl></ExperimentControl>
                      <ExperimentVariant bucket={1}>
                        <CustomerNotifications location={CustomerNotificationLocation.AFTER_HEADER} />
                      </ExperimentVariant>
                    </Experiment>
                    {data?.helpCenterPersonalization?.helpCenterPersonalizedView?.banner && (
                      <WelcomeBanner data={data?.helpCenterPersonalization?.helpCenterPersonalizedView?.banner} />
                    )}
                    {(data?.helpCenterPersonalization.helpCenterPersonalizedView.isVacEnabled ?? false) && (
                      <VaContainer
                        data={data.helpCenterPersonalization.helpCenterPersonalizedView}
                        userHasTrip={
                          data?.helpCenterPersonalization?.helpCenterPersonalizedView?.travelerTripDetailedOverview
                            ?.allTrips?.length > 0
                        }
                      />
                    )}
                    {data?.helpCenterPersonalization?.helpCenterPersonalizedView?.travelerTripDetailedOverview && (
                      <Trips
                        data={data.helpCenterPersonalization.helpCenterPersonalizedView?.travelerTripDetailedOverview}
                      />
                    )}
                  </div>
                </div>
                {data?.helpCenterPersonalization?.helpCenterSignInCTAView && (
                  <SignInCta data={data.helpCenterPersonalization.helpCenterSignInCTAView} />
                )}
                <div className={'middle-con global_navigation__header__default__max_width'}>
                  <UitkSpacing margin={{ blockstart: 'twelve' }}>
                    <div>
                      {data?.helpCenterPersonalization.helpCenterPersonalizedView.taapAcademyComponent && (
                        <TaapAcademy
                          data={data?.helpCenterPersonalization.helpCenterPersonalizedView.taapAcademyComponent}
                        />
                      )}
                    </div>
                  </UitkSpacing>
                  <div role="region" aria-label="Explore help articles" className={'articles-div'}>
                    {renderIntelligentSearch && (
                      <UitkSpacing margin={{ blockend: 'four' }}>
                        <HelpCenterSearch ssr />
                      </UitkSpacing>
                    )}
                    {renderIntelligentSearch && (
                      <UitkSpacing margin={{ blockstart: 'six' }}>
                        <UitkHeading tag="h2" size={5}>
                          {data?.helpCenterPersonalization.helpCenterPersonalizedView.helpArticleTitle}
                        </UitkHeading>
                      </UitkSpacing>
                    )}
                    <HelpArticles />
                  </div>
                  <UitkSpacing padding={{ blockstart: 'two' }}>
                    <AppDownloadBanner />
                  </UitkSpacing>

                  <div className={`separator`} />
                </div>
              </div>
              <Footer />
            </div>
        </VirtualAgentContextProvider>
      </ErrorBoundary>
    );
  }
);

HelpCenter.displayName = 'HelpCenter';

export default HelpCenter;
