import * as React from 'react';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkCard, UitkCardContentSection, UitkCardLink } from 'uitk-react-cards';
import { UitkFigure, UitkFigureAspectRatioType, UitkImage } from 'uitk-react-images';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkScrim, UitkScrimType } from 'uitk-react-scrim';
import { UitkBadgeStandard } from 'uitk-react-badge';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import {
  ViewLarge,
  Viewport,
  ViewSmall,
  defaultViewSizes,
  useMediaQueries,
} from '@shared-ui/viewport-context';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { buildClickStreamAnalyticsForTripSelectedEvent } from 'common/helper';
import { useState } from 'react';
import { TripInfoDetailFields } from 'common/__generated__/api/types';
import TripDetails = TripInfoDetailFields.AllTrips;

interface TripCardProps {
  data: TripDetails;
  setTripInfoData: (value) => void;
}

const TripInfoOverview: React.FC<TripCardProps> = ({ data, setTripInfoData }: TripCardProps) => {
  const trackClick = useEgClickstream();
  const viewSize = useMediaQueries({ viewSizes: defaultViewSizes });
  const [selected, setSelected] = useState(0);

  const figureContent = (trip) => {
    return (
      <>
        <UitkImage placeholderImage={true} alt={trip?.image?.description} src={trip?.image?.url} />
        <UitkScrim type={UitkScrimType.OVERLAY}>
          <UitkCardContentSection>
            <UitkLayoutFlex direction={'column'} justifyContent={'space-between'} space={'one'}>
              <UitkLayoutFlexItem>
                <UitkHeading tag="h4" size={4}>
                  {trip.tripName}
                </UitkHeading>
              </UitkLayoutFlexItem>
              <UitkLayoutFlexItem>
                <UitkText>{trip.timePeriod}</UitkText>
              </UitkLayoutFlexItem>
              <UitkLayoutFlexItem>
                <UitkText>{trip.numberOfBooking}</UitkText>
              </UitkLayoutFlexItem>
              <UitkLayoutFlexItem>
                {trip.tripBookings.length > 0 && (
                  <UitkLayoutFlex space={'two'}>
                    {trip.bookingIcons.map((el, i) => (
                      <UitkBadgeStandard key={i} theme={'standard'}>
                        <UitkIcon name={el.id} size={UitkIconSize.SMALL} />
                      </UitkBadgeStandard>
                    ))}
                  </UitkLayoutFlex>
                )}
              </UitkLayoutFlexItem>
            </UitkLayoutFlex>
          </UitkCardContentSection>
        </UitkScrim>
      </>
    );
  };

  return (
    <>
      {data.tripInfos.map((trip, index) => (
        <UitkSpacing margin={{ blockend: 'three', inlineend: 'two' }} key={`trip-${index}`}>
          <UitkCard selected={viewSize !== defaultViewSizes.SMALL && selected === index}>
            <Viewport>
              <ViewSmall>
                <UitkFigure ratio={UitkFigureAspectRatioType.R21_9}>{figureContent(trip)}</UitkFigure>
              </ViewSmall>
              <ViewLarge>
                <UitkFigure ratio={UitkFigureAspectRatioType.R4_1}>{figureContent(trip)}</UitkFigure>
              </ViewLarge>
            </Viewport>
            <UitkCardLink>
              <button
                onClick={() => {
                  setTripInfoData(trip);
                  setSelected(index);
                  trackClick(
                    buildClickStreamAnalyticsForTripSelectedEvent(trip.tripName, trip.numberOfBooking.match(/\d+/)[0])
                  );
                }}
              >
                {trip.tripName}
              </button>
            </UitkCardLink>
          </UitkCard>
        </UitkSpacing>
      ))}
    </>
  );
};

export default TripInfoOverview;
