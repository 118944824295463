import { gql } from '@apollo/client';

export const BookingActionsClickStreamAnalyticsFragment = gql`
  fragment BookingActionsClickStreamAnalyticsFragment on BookingActionsClickStreamAnalytics {
    event {
      eventName
      eventType
      eventCategory
      eventVersion
      actionLocation
      actionReason
      __typename
    }
    tripDetail {
      tripDomainId
      __typename
    }
    experience {
      pageName
      appName
      appVersion
      pageNameDetailed
      __typename
    }
    workFlowList {
      intent
      orderLineId
      productLine
      subIntent
      automationStatus
      automationStatusReason
      channel
      __typename
    }
    __typename
  }
`;
